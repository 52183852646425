.App {
  text-align: center;
}

.container {
  min-height: 100vh;
  position: relative;
  padding-bottom:60px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}